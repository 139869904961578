import React from "react";
import Button from "@material-ui/core/Button";
import {CSVLink} from "react-csv";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";

export const CsvButton = (props) => {
  const revisionTitle = props.revision.getIsAdjustment() ? "Ajuste por inflacion -" : "Revision de rates - ";
  const filename = revisionTitle + props.revision.getImpactDate();
  const getCSVData = () => Array.from(props.pines).map(pine => ([props.revision.impactDate, pine.juridicPersonId, "OTRAS", pine.getNewRateOfPine()]));


  return <Button style={{height: "fit-content", margin: "0 1rem"}} color={"primary"} variant={"outlined"} size={"large"}>
    <CSVLink style={{marginRight: "1rem"}} filename={filename} data={getCSVData()}> Exportar a
            CSV </CSVLink>
    <FontAwesomeIcon icon={faFileDownload} color={"#3f51b5"} size={"2x"}/>
  </Button>;


};

export default CsvButton;
