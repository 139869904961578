import React from "react";
import {Button} from "@material-ui/core";
import {CanvasJSChart} from "../../../canvasjs.react";
import {PercentageSquare} from "../information/RiseSquare";
import {faArrowCircleUp, faMoneyCheckAlt, faWrench} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextField from "@material-ui/core/TextField";
import {formatDateToMonthYear, isMediumScreenOrLess, revertArrayNonDestructively} from "../commons";
import {Spinner} from "../Spinner";
import moment from "moment";
import _ from "lodash";

const bodyContainerStyleHorizontal = {
  display: "flex",
  flexDirection: "row",
  width: "100%"
};

const bodyContainerStyleVertical = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%"
};

const bodyContainerStyle = isMediumScreenOrLess() ? bodyContainerStyleVertical : bodyContainerStyleHorizontal;

const buttonSectionStyle = {
  marginTop: "2rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: isMediumScreenOrLess() ? "center" : "flex-end",
  paddingRight: "2rem"
};

const chartStyle = {
  width: "35rem"
};

const risesListStyle = {
  width: "20em",
  marginLeft: "2rem",
  marginTop: "1.7rem"
};

const riseLineContainerStyle = {
  borderTop: "1px solid lightgrey",
  height: "4rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start"
};

const chartContainerStyle = {
  width: "40rem",
  padding: "1rem",
  background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
  boxShadow: "2px 2px 7px #bababa",
  borderRadius: "10px"
};

const selectedProposalContainerStyle = (shouldShow) => ({
  background: "transparent",
  border: "1px solid black",
  borderRadius: "10px",
  paddingLeft: shouldShow ? "2rem" : 0,
  paddingTop: "1rem",
  width: shouldShow ? "20rem" : 0,
  display: shouldShow ? "flex" : "none",
  flexDirection: "column",
  opacity: shouldShow ? "1" : 0,
  transition: "all 0.8s ease",
  overflow: "hidden"
});

const proposalIconStyle = {
  height: "2rem",
  width: "2rem",
  cursor: "pointer",
  marginRight: "1rem"
};

const textFieldStyle = {
  background: "transparent",
  width: "18rem",
  marginBottom: "1rem"
};

const riseTypeIconStyle = {
  height: "1.5rem",
  width: "1.5rem",
  marginLeft: "1rem"
};

const risesItemsContainerStyle = {
  height: "20em",
  overflowY: "scroll",
};

export class PineLastRisesSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {selectedProposal: null, loading: true, filterAdjustments: false};
  }

  componentDidMount() {
    this.props.pine.getRises().then(response => {
      this.setState({lastRises: response, listedRises: response, loading: false});
    });
  }

  getProposalFieldOr(proposalField, defaultValue) {
    //This is because we're mounting the section anyway to reduce the animation code overhead
    return this.state.selectedProposal ? this.state.selectedProposal[proposalField] : defaultValue;
  }

  onProposalClick = async (event) => {
    const rise = this.state.lastRises.find(rise => rise["fecha"] === event.currentTarget.id);
    this.setState({selectedProposal: !this.state.selectedProposal || this.state.selectedProposal["fecha"] !== rise["fecha"] ? rise : null});
  };

  getRisesDataPoints = (includeAdjustments) => {
    if(_.isEmpty(this.state.listedRises)){
      return [];
    }
    const revertedRises = revertArrayNonDestructively(this.state.listedRises);

    const realRises = this.state.listedRises.filter(rise => !rise.esAjuste);
    const initialDateToDraw = this.getInitialDateToDraw(realRises,this.props.pine.startDate);
    const initialValue = [{x: initialDateToDraw, y: 0}];

    const lastRise = revertedRises[revertedRises.length - 1];
    let lastNonAdjustmentRiseIndex = 0;
    const filteredRises = includeAdjustments ? revertedRises : revertedRises.filter(rise => !rise["esAjuste"]);
    const dataPoints = filteredRises.map((rise, index) => {
      if(!rise["esAjuste"]) lastNonAdjustmentRiseIndex = index;
      return {x: new Date(rise["fecha"]), y: this.calculatePercentageUntil(filteredRises, index)};
    });

    let resultArray = initialValue.concat(dataPoints);
    // If adjustments are not included, we still need to end at the same spot in order the graph to be nice
    if(!includeAdjustments && lastRise["esAjuste"]) {
      return resultArray.concat({x: new Date(lastRise["fecha"]), y: this.calculatePercentageUntil(filteredRises, lastNonAdjustmentRiseIndex)});
    } else {
      return resultArray;
    }

  };

  getInitialDateToDraw(realRises,pineStartDate) {
    const firstRise = _.last(realRises);
    if(firstRise){
      const lastProposalBeforeEntry = moment(firstRise.fecha).clone();
      lastProposalBeforeEntry.subtract(4, "month");
      return lastProposalBeforeEntry;
    }
    return moment(pineStartDate);
  }

  calculatePercentageUntil = (rises, riseIndex) => {
    return rises.slice(0, riseIndex + 1).reduce((acc, rise) => {
      const percentage = parseInt(rise["porcentaje"]);
      const percentageOverAcc = percentage*acc / 100; // esto se hace para calcular el porcentaje del acumulado. Al no sumarse simplemente los porcentajes, se debe considerar que si aumento 7% del sueldo, y tengo otra revisiones, no aumento realmente 7% en el grafico, sino que aumento el 7% + el 7% de los porcentajes acumulados!
      return percentage + acc + percentageOverAcc;
    }, 0);
  };

  getGraphOptions = () => {
    return {
      theme: "light2",
      colorSet: "colorSet2",
      backgroundColor: "rgba(0, 0, 0, 0)",
      title: {
        text: "Evolución histórica",
        fontFamily: "'Lato', sans-serif",
        fontWeight: "200",
      },
      subtitles: [{
        text: "Aumento acumulado sobre aumentos por performance"
      }],
      axisY: {
        includeZero: true,
        prefix: ""
      },
      toolTip: {
        shared: true
      },
      data: [
        {
          type: "area",
          name: "Acumulado incluyendo Ajustes",
          showInLegend: true,
          xValueFormatString: "MMM YYYY",
          dataPoints: this.getRisesDataPoints(true)
        },
        {
          type: "area",
          name: `Camino de ${this.props.pine.name}`,
          showInLegend: true,
          xValueFormatString: "MMM YYYY",
          dataPoints: this.getRisesDataPoints(false)
        }
      ]
    };
  };

  handleAdjustmentFiltering = (event) => {
    const shouldFilterAdjustments = event.target.checked;
    const newListedRises = shouldFilterAdjustments ? this.state.lastRises.filter(rise => !rise["esAjuste"]) : this.state.lastRises;
    this.setState({filterAdjustments: shouldFilterAdjustments, listedRises: newListedRises});
  };

  render() {
    if (this.state.loading) return <Spinner />;

    return (
      <>
        <div style={bodyContainerStyle}>
          <div style={chartContainerStyle}>
            <CanvasJSChart style={chartStyle} options={this.getGraphOptions()}/>
          </div>
          <div style={risesListStyle}>
            <div style={{display: "flex", flexDirection: "column"}}>
              <b> Últimos aumentos </b>
              <label style={{cursor: "pointer", display: "flex", alignItems: "baseline", margin: "1em 0", color: this.state.filterAdjustments ? "black" : "grey"}}>
                <input style={{cursor: "pointer", margin: "0 0.5em 0 0"}} type={"checkbox"} checked={this.state.filterAdjustments} onChange={this.handleAdjustmentFiltering}/>
                Sólo aumentos por desempeño
              </label>
            </div>
            <div style={risesItemsContainerStyle}>
              {this.state.listedRises.map(rise => {
                let isAdjustment = rise["esAjuste"];
                return <div style={riseLineContainerStyle} key={rise["fecha"]}>
                  <span style={{width: "4rem"}}> {formatDateToMonthYear(rise["fecha"])} </span>
                  {isAdjustment ?
                    <div title={"Ajuste"} style={{marginRight: "1rem"}}>
                      <FontAwesomeIcon style={riseTypeIconStyle} icon={faWrench} color={"#5C648A"}/>
                    </div>
                    :
                    <div title={"Aumento"} style={{marginRight: "1rem"}}>
                      <FontAwesomeIcon style={riseTypeIconStyle} icon={faArrowCircleUp} color={"green"}/>
                    </div>
                  }
                  <PercentageSquare percentage={rise["porcentaje"]}/>
                  <FontAwesomeIcon style={proposalIconStyle} icon={faMoneyCheckAlt} color={"black"}
                    onClick={this.onProposalClick} id={rise["fecha"]}/>
                </div>;
              }
              )}
            </div>
          </div>
          <div style={selectedProposalContainerStyle(!!this.state.selectedProposal)}>
            <b style={{width: "20rem", marginBottom: "1rem"}}> Detalles de la propuesta
              del {formatDateToMonthYear(this.getProposalFieldOr("fecha", ""))} </b>

            <TextField style={textFieldStyle} readOnly label="Porcentaje" variant="filled"
              value={this.getProposalFieldOr("porcentaje", "") + "%"}/>
            <TextField style={textFieldStyle} readOnly label="Sponsors" variant="filled"
              value={(this.getProposalFieldOr("sponsors", null) || []).map(sponsor => sponsor["nombre"]).join(", ")}/>
            <TextField style={textFieldStyle} readOnly label="Franja" variant="filled"
              value={this.getProposalFieldOr("franja", "")}/>
            <TextField style={textFieldStyle} readOnly multiline maxRows={4} id="filled-basic" label="Descripción"
              variant="filled" value={this.getProposalFieldOr("descripcion", "")}/>

          </div>
        </div>
        <div style={buttonSectionStyle}>
          <Button variant={"outlined"} onClick={this.props.onCancel}> Salir </Button>
        </div>
      </>
    );
  }
}
