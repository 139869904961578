import {PinesRepository} from "../repositories/PinesRepository";
import {formatDateToMonthAndYearInWords, formatDateToMonthYear, sortPinesByRate} from "../../sharedComponents/commons";
import {RevisionsRepository as RevisionRepository} from "../repositories/RevisionsRepository";
import {Pine} from "./Pine";

export class Revision {

  constructor(rawRevisionData) {
    this.isAdjustment = rawRevisionData.esAjuste;
    this.date = rawRevisionData.fecha;
    this.currentProposedPineId = rawRevisionData.pinoActualEnRevisionId;
    this.isOpen = rawRevisionData.estaAbierta;
    this.impactDate = rawRevisionData.fechaDeImpacto;
    this.id = rawRevisionData.id;
    this.isExtraordinary = rawRevisionData.esExtraordinaria;
    if (rawRevisionData.pinos) {
      const pines = rawRevisionData.pinos.map(pineRawData => new Pine(pineRawData));
      this.pines = sortPinesByRate(pines);
    }
  }

  getId() {
    return this.id;
  }

  getIsExtraordinary() {
    return !!this.isExtraordinary;
  }

  getIsOpen() {
    return this.isOpen;
  }

  getIsAdjustment() {
    return this.isAdjustment;
  }

  getDate() {
    return this.date;
  }

  getCurrentProposedPineId() {
    return this.currentProposedPineId;
  }

  getMonthYearFormattedDate() {
    return formatDateToMonthYear(this.getDate());
  }

  getImpactDate() {
    return this.impactDate;
  }

  getName() {
    const revisionString = this.getIsAdjustment() ? "Ajuste" : "Revisión";
    return revisionString + " de " + formatDateToMonthAndYearInWords(this.getDate());
  }

  setCurrentProposedPineId(pineId) {
    this.currentProposedPineId = pineId;
    return RevisionRepository.updateCurrentProposedPineId(pineId).then(r => r);
  }

  nextProposalReview(pine,pineWithProposals) {
    return PinesRepository.getNextProposedPineId(pine.getId(),pineWithProposals).then(pineId => {
      return this.setCurrentProposedPineId(pineId);
    });
  }

  previousProposalReview(pine, pineWithProposals) {
    return PinesRepository.getPreviousProposedPineId(pine.getId(), pineWithProposals).then(pineId => {
      return this.setCurrentProposedPineId(pineId);
    });
  }

  impactSalaryPercentage(){
    const salaryBeforeRevision = this.getSalaryBeforeRevision();
    const salaryAfterRevision= this.getSalaryAfterRevision();
    return (salaryAfterRevision - salaryBeforeRevision) / salaryBeforeRevision * 100;
  }

  getSalaryAfterRevision() {
    return _.sumBy(this.pines, pine => {
      return pine.getProposal().isProposalPreConfirmed() ? pine.getMostUpdatedSalary() : pine.getOldSalary();
    });
  }

  getSalaryBeforeRevision() {
    return _.sumBy(this.pines, pine => pine.getOldSalary());
  }

  static closeRevision(impactDate) {
    return PinesRepository.closeActualRevision(impactDate);
  }

  hasPine(pineId){
    return this.pines.some(pine => pine.getId() === pineId);
  }
  getPines() {
    return this.pines;
  }
  setPines(pines){
    this.pines = pines;
  }
  replacePine(newPine){
    this.pines = this.pines.map(pine => pine.getId() === newPine.getId() ? newPine : pine);
  }
}
