import React from "react";
import GlowingPineSpinner from "glowing-pine-spinner";

const containerStyle = {width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"};

export const Spinner = (props) => (
  <div style={containerStyle} >
    <GlowingPineSpinner
      size={props.size || 70}
      duration={1}
      strokeWidth={10}
      firstColor={props.color || "#9cb8e1"}
      secondColor={props.color || "#6da1cc"}
      thirdColor={props.color || "#3ea1e1"}
      glow={false}
    />
  </div>
);
