import React from "react";
import {Coupon} from "../../Coupon/Coupon";

const divStyle = () => ({
  background: "lightgrey",
  width: "4rem",
  maxWidth: "4rem",
  height: "3rem",
  borderRadius: "30%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "2rem",
});

const textStyle = (isTherePercentage) => ({
  color: isTherePercentage ? "black" : "grey",
  fontSize: "1.1rem"
});

export const RiseSquare = (props) => {
  if(props.isAdjustment){
    return <PercentageSquare percentage={props.percentage || props.pine.getProposalPercentage()}/>;
  }
  else
    return <Coupon pine={props.pine} percentage={props.percentage} size='compact'/>;
};

export function PercentageSquare({percentage}){
  const label = percentage ? `${percentage}%` : "N/A";
  return <div style={divStyle()}>
    <span style={textStyle(!!percentage)}> {label} </span>
  </div>
}
