import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";


const containerStyle = {
  marginBottom: "1rem"
};

const headerContainerStyle = (showOptions,width) => ({
  width: width || "33rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "1rem",
  borderRadius: showOptions ? "10px 10px 0 0" : "10px",
  cursor: "pointer",
  background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
  boxShadow: "2px 2px 7px #bababa",
});

const revisionContainerStyle = {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  paddingLeft: "1rem",
  justifyContent: "flex-start"
};

const optionsStyle = (width) => ({
  background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
  boxShadow: "2px 2px 7px #bababa",
  borderRadius: "0 0 10px 10px",
  width: width || "33rem",
  maxHeight: "18rem",
  overflowY: "scroll",
  position: "absolute",
  zIndex: "100",
  paddingBottom: "1rem",
});

const itemStyle = {
  fontSize: "1rem",
  fontWeight: "200",
  paddingLeft: "1rem",
  paddingTop: "0.5rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  cursor: "pointer"
};

const iconStyle = {
  width: "1rem",

};

export const Dropdown = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const handleDropdownClick = () => {
    setShowOptions(!showOptions);
  };

  const handleProposalSelection = (event) => {
    const selectedOption = props.options[parseInt(event.currentTarget.id)];
    props.onSelected(selectedOption);
    setShowOptions(false);
  };

  return <div style={containerStyle}>
    <div style={headerContainerStyle(showOptions,props.width)} onClick={handleDropdownClick}>
      <div style={revisionContainerStyle}>
        {props.onRenderSelectedOption(props.selected)}
      </div>
      <FontAwesomeIcon style={{...iconStyle, paddingRight: "1rem"}} icon={faChevronDown} color={"grey"}/>
    </div>
    {showOptions &&
        <div style={optionsStyle(props.width)}>
          {props.options.map((option, index) =>
            <div style={itemStyle} id={index} key={index}
              onClick={handleProposalSelection}>
              {props.onShowOption(option)}
            </div>
          )
          }
        </div>
    }
  </div>;

};

export default Dropdown;
