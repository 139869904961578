import React from "react";
import {Button} from "@material-ui/core";
import {Spinner} from "../Spinner";

const containerStyle = (isLoading) => ({
  opacity: isLoading ? "0.7" : "1",
  transition: "all 0.5s ease"
});

export class CallToActionButton extends React.Component {

  state = {isLoading: false};

  handleClick = () => {
    if(!this.state.isLoading){
      this.setState({isLoading: true});
      this.props.onClick().then(() => this.setState({isLoading: false}));
    }
  };

  render() {
    return (
      <Button style={containerStyle(this.state.isLoading)} disabled={this.props.isDisabled} color={"primary"} variant={"outlined"}
        onClick={this.handleClick}>
        {this.props.label}
        {this.state.isLoading && <div style={{height: "1rem"}}> <Spinner size={20} color={"blue"}/> </div>}
      </Button>
    );
  }
}
