import {RiseSquare} from "../sharedComponents/information/RiseSquare";
import {ProposalData} from "../sharedComponents/information/ProposalData";
import React from "react";
import {revertArrayNonDestructively} from "../sharedComponents/commons";


const nearPineListStyle = (nearPine, proposedPine) => {
  let basicStyle = {
    display: "flex",
    flexDirection: "row",
    height: "5rem",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid lightgrey"
  };

  if (nearPine.getId() === proposedPine.getId()) {
    basicStyle = {...basicStyle, height: "7rem", background: "rgb(0,0,0,0.1)"};
  } else {
    basicStyle = {...basicStyle, opacity: "0.5"};
  }

  return basicStyle;
};

const nameStyle = {
  width: "10rem",
  fontWeight: "700",
  marginLeft: "2rem"
};

const titleStyle = {...nameStyle, marginLeft: "3.5rem", fontWeight: "200", fontSize: "1.5rem", marginBottom: "1rem", width: "15rem"};


class NearPines extends React.PureComponent {
  componentDidMount() {
    this.proposedPine.focus();
  }

  showPine = (nearPine, proposedPine) => {
    let props = {};
    if (nearPine.getId() === proposedPine.getId()) {
      props = {
        tabIndex: "-1",
        ref: (element) => {
          this.proposedPine = element;
        }
      };
    }
    return <div className={"pinos-cercanos"} {...props} key={nearPine["id"]} style={nearPineListStyle(nearPine, proposedPine)}>
      <div style={{flexGrow: "2"}}>
        <p style={nameStyle}>{nearPine.getName()}</p>
      </div>
      <div className={"info-pino"}>
        <RiseSquare pine={nearPine}/>
        <ProposalData className={"franja"} pine={nearPine} valueKey={"franja"}/>
        <ProposalData pine={nearPine} valueKey={"rate"}/>
      </div>
    </div>;
  };

  render() {
    let {pines, proposedPine} = this.props;
    const sortedPines = revertArrayNonDestructively(pines);
    return <React.Fragment>
      <b style={titleStyle}> Pinos Cercanos </b>
      <div style={{
        "minHeight": "10rem", "overflowY": "scroll", display: "flex",
        flexDirection: "column",
      }}>

        {
          sortedPines.map(nearPine =>
            this.showPine(nearPine, proposedPine)
          )

        }
      </div>

    </React.Fragment>;
  }
}

export default NearPines;
