import React from "react";
import {AdjustedValue} from "../sharedComponents/information/AdjustedValue";
import {calculatedAdjustedValue} from "./AdjustmentPage";
import {BenefitsRepository} from "../businessLogic/repositories/BenefitsRepository";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  width: "15rem",
};

const lineContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  borderTop: "1px solid lightgrey",
  height: "2.6rem",
};

export const AdjustedBenefits = props => {
  const {selectedPercentage} = props;
  const benefits = BenefitsRepository.getCurrentBenefits();
  return (
    <div style={containerStyle}>
      <h4> Beneficios </h4>
      {benefits.map(benefit =>
        <div key={benefit.getId()} style={lineContainerStyle}>
          <span style={{fontSize: "0.9rem"}}> {benefit.getName()} </span>
          <AdjustedValue isAdjusted={props.isAdjusted} textMask={"$"} previousValue={benefit.getValue()} newValue={calculatedAdjustedValue(benefit.getValue(), selectedPercentage)}/>
        </div>
      )}
    </div>
  );
};
