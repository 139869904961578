import moment from "moment";

export const impactMonthOptions = {
  actualMonth: {
    name: "Mes actual",
    getDateForCsv: (fecha) => {
      return moment(fecha).clone().startOf("month");
    }},
  pastMonth: {
    name: "Mes anterior",
    getDateForCsv: (fecha) => {
      return moment(fecha).clone().startOf("month").subtract(1,"month");
    }
  },
  nextMonth: {
    name: "Mes siguiente",
    getDateForCsv: (fecha) => {
      return moment(fecha).clone().startOf("month").add(1,"month");
    }
  },
};

export default [impactMonthOptions.actualMonth,impactMonthOptions.nextMonth,impactMonthOptions.pastMonth];
