import React from "react";

const riseOptionContainerStyle = (color, secondaryColor) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.7em 2em",
    background: `linear-gradient(45deg, ${color}, ${secondaryColor})`,
    borderRadius: "10px",
  };
};

const textStyle = {
  color: "white",
  fontWeight: "900"
};

export function MediumCoupon({defaultText, color, secondaryColor}) {

  return <div style={riseOptionContainerStyle(color, secondaryColor)} >
    <span style={textStyle}>
      { defaultText }
    </span>
  </div>;
}
