import React, {useState} from "react";
import {WeakSponsorThumbsUpEditableContainer as WeakSponsorThumbsUp} from "../../proposalsPage/WeakSponsorThumbsUp";
import {RiseSquare} from "../information/RiseSquare";
import {ProposalData} from "../information/ProposalData";
import {GardenerIcon} from "../icons/GardenerIcon";
import {ProposalStatusIcon} from "../icons/ProposalStatusIcon";
import SlideTransition from "../SlideTransition";
import {withAuthContext} from "../../AuthContainer";
import {ProposalTabs} from "./ProposalTabs";

export const PineItem = props => {

  const [open, setOpen] = useState(false);

  const onProposalClick = () => setOpen(!open);

  const handleAddProposal = async (proposalArgs) => {
    await props.onAddProposal(proposalArgs);
    setOpen(false);
  };

  const handleProposalCancellation = () => setOpen(false);

  const handleExitLatestRaises = () => setOpen(false);

  const isAdjustment = props.revisionData.isAdjustment;
  return (
    <div className="fila-container">
      <div className="fila" onClick={onProposalClick}>
        <WeakSponsorThumbsUp pine={props.pine}
          onWeakSponsorToggle={props.onWeakSponsorToggle}
          readOnly={!props.revisionData.isOpen || !props.pine.hasProposal()}/>
        <ProposalStatusIcon pine={props.pine}/>
        <RiseSquare pine={props.pine} isAdjustment={isAdjustment}/>
        <p className="nombre">{props.pine.getName()}</p>
        <ProposalData pine={props.pine} valueKey={"franja"} className={"franja"} shouldHideDifference={isAdjustment}/>
        <ProposalData pine={props.pine} valueKey={"rate"} className={"rate"} textMask={"$"}/>
        <ProposalData pine={props.pine} valueKey={"bruto"} className={"bruto"} textMask={"$"}/>
        <ProposalData pine={props.pine} valueKey={"fechaUltimoAumento"} className={"fecha-ultimo-aumento"}/>

        <GardenerIcon pine={props.pine} currentPine={props.loggedPine}/>
      </div>
      <SlideTransition isOpen={open} height="39rem">
        { () => <ProposalTabs pine={props.pine}
          revisionData={props.revisionData}
          onAddProposal={handleAddProposal}
          handleProposalCancellation={handleProposalCancellation}
          readOnly={props.shouldItemsByReadOnly}
          allPines={props.allPines}
          handleLastRisesExit={handleExitLatestRaises}
        />
        }
      </SlideTransition>
    </div>
  );
};

export default withAuthContext(PineItem);
