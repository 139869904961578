import React from "react";

const divStyle = (color, secondaryColor) => ({
  background: `linear-gradient(45deg, ${color}, ${secondaryColor})`,
  width: "4rem",
  height: "3rem",
  borderRadius: "30%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "2rem",
});

const textStyle = (color) => ({
  color: "white",
  fontSize: "1.3em",
  fontWeight: "900",
});

export function Square(props) {
  return <div style={divStyle(props.color, props.secondaryColor)} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
    <span style={textStyle(props.secondaryColor)}> {props.children} </span>
  </div>;
}
