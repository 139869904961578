import React, {Component} from "react";
import {Icon} from "../icons/Icon";
import { faTree } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {withAuthContext} from "../../AuthContainer";

const containerStyle = {
  height: "3.5em",
  position: "relative"
};

const divStyle = (left) => {
  return {
    alignItems: "center",
    backgroundColor: "black",
    borderRadius: ".5em",
    color: "white",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    padding: "0 .75em",
    position: "absolute",
    left: `${left}`,
    transition: ".8s",
    width: "20em",
    zIndex: "1"
  };
};

const dropdownStyle = {
  backgroundColor: "lightgrey",
  borderLeft: ".5em solid black",
  borderRadius: ".5em 0 0 .5em",
  height: "100%",
  left: "0",
  position: "absolute",
  width: "18em",
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  padding: "0 1em"
};

const pStyle = {
  margin: "0 0 0 .5em",
  textTransform: "uppercase"
};

const actions = {
  WILL_ADD: {
    buttonLabel: "Agregar jardinereado",
    callback: (props, selectedPine) => { props.onAddGardener(props.loggedPine, selectedPine);}
  },
  WILL_REMOVE: {
    buttonLabel: "Eliminar jardinereado",
    callback: (props, selectedPine) => {props.onRemoveGardener(props.loggedPine, selectedPine);}
  },
  NONE: {
    buttonLabel: "Gestionar jardinereados",
    callback: () => {}
  }
};

export class WebcamCoverDropdown extends Component {

  state = { open: false, action: actions.NONE, selectedOption: {} };

  executeCallback = (seleccionado, accion) => {
    accion.callback(this.props, seleccionado);
  };

  handleClick = () => {
    if (this.state.action !== actions.NONE) {
      this.executeCallback(this.state.selectedOption, this.state.action);
    }
    this.setState({ open: !this.state.open, action: actions.NONE, selectedOption: {} });
  };

  handleChange = (event, pine) => {
    if (pine) {
      this.setState({ selectedOption: pine, action: pine.isPineGardener(this.props.loggedPine) ? actions.WILL_REMOVE : actions.WILL_ADD });
    } else {
      this.setState( { selectedOption: {}, action: actions.NONE });
    }
  };

  render() {
    return (
      <div id="webcam-cover-dropdown" style={containerStyle}>
        <div style={dropdownStyle}>
          { this.state.open && <Autocomplete
            id="dropdown"
            options={this.props.options}
            getOptionLabel={option => option.getName()}
            style={{ width: "14.5em" }}
            margin="none"
            onChange={this.handleChange}
            clearOnEscape={true}
            renderInput={params => (
              <TextField {...params} ref={this.ref} variant="filled" autoFocus fullWidth />
            )}
          /> }
        </div>
        <div style={divStyle(this.state.open ? "17.5em" : "0")} onClick={this.handleClick}>
          <Icon icon={faTree} borderColor={"white"}/>
          <p style={pStyle}>{this.state.action.buttonLabel}</p>
        </div>
      </div>
    );
  }
}

export default withAuthContext(WebcamCoverDropdown);