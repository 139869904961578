import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTree} from "@fortawesome/free-solid-svg-icons";

const iconStyle = {
  marginRight: "1rem",
  marginLeft: "1rem",
  cursor: "pointer"
};

const calculateGardenerColor = (pine, currentPine) => {
  if(!pine.hasGardeners()) return "lightgrey";
  return currentPine && pine.isPineGardener(currentPine) ? "green" : "black";
};

const tooltipText = (pine) => {
  if(!pine.hasGardeners()) return pine.getName() + " no tiene jardineres asignados";
  return pine.getGardenersNames().join(" ");
};

export const GardenerIcon = (props) => (
  <div title={tooltipText(props.pine)} className={"jardinero"}>
    <FontAwesomeIcon style={iconStyle} icon={faTree} color={calculateGardenerColor(props.pine, props.currentPine)} size="2x"/>
  </div>
);
