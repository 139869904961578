import React from "react";
import {faUser, faUsers, faWrench, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {DialOption} from "./DialOption";
import routes from "../Routes";
import {withRouter} from "react-router-dom";
import {actualPath} from "../sharedComponents/commons";

const isSelected = (path) => {
  return actualPath() === path;
};

const PageDial = (props) => {
  const redirectTo = (url) => {
    props.history.push(url);
  };

  return <div className={"page-dial"}>
    <DialOption onClick={() => redirectTo(routes.proposals)} icon={faUser} label={"PROPUESTAS"}
      isSelected={isSelected(routes.proposals)}/>
    <DialOption onClick={() => redirectTo(routes.proposalReviews)} icon={faCheckCircle} label={"REVISIÓN"}
      isSelected={isSelected(routes.proposalReviews)}/>
    <DialOption onClick={() => redirectTo(routes.adjustmentRevision)} icon={faWrench} label={"AJUSTE"}
      isSelected={isSelected(routes.adjustmentRevision)}/>
    <DialOption onClick={() => redirectTo(routes.pines)} icon={faUsers} label={"PINES Y JARDINERES"}
      isSelected={isSelected(routes.pines)}/>
  </div>;
};

export default withRouter(PageDial);
