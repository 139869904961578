import React from "react";


const percentageStyle = (isSelected) => ({
  fontWeight: "700",
  fontSize: "3rem",
  width: "15rem",
  color: isSelected ? "white" : "black",
});

const riseOptionContainerStyle = (isSelected, color, secondaryColor) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    background: isSelected ? `linear-gradient(45deg, ${color}, ${secondaryColor})` : "transparent",
    color: isSelected ? "white" : "black",
    width: "30rem",
    height: "6rem",
    borderRadius: "10px",
    marginBottom: "2rem",
    cursor: "pointer",
    border: "2px solid black",
  };
};

const titleStyle = {
  fontSize: "1.3em",
  fontWeight: "900",
};

const textContainer = {
  display: "flex",
  flexDirection: "column"
};

export function RawLargeCoupon({isSelected,onClick,children,description, color, secondaryColor, title} ) {
  return <div
    style={riseOptionContainerStyle(isSelected, color, secondaryColor)}
    onClick={onClick}>
    {children}
    <div style={textContainer}>
      <span style={titleStyle}>{title}</span>
      <span>{description}</span>
    </div>
  </div>;
}

export function LargeCouponText({isSelected, children}){
  return <span style={percentageStyle(isSelected)}>{children}</span>;
}
