import React from "react";
import {CompactCoupon} from "./CompactCoupon";
import {RawLargeCoupon, LargeCouponText} from "./LargeCoupon";
import {MediumCoupon} from "./MediumCoupon";

function LargeCoupon({isSelected,onClick, color, secondaryColor}) {
  return <RawLargeCoupon
    isSelected={isSelected}
    onClick={onClick}
    color={color}
    title={"No se Propone"}
    secondaryColor={secondaryColor}
    description={<>Aunque no se presente a la revisión, es útil saberlo en la reunión de rates para no dudar sobre si hubo algún tipo de olvido</>}
  >
    <LargeCouponText isSelected={isSelected}>NP</LargeCouponText>
  </RawLargeCoupon>;
}

export function NotProposed({size,isSelected,onClick}) {
  if(size === "compact"){
    return <CompactCoupon secondaryColor="gold" color="orangered" defaultText={"NP"} textOnHover={"0%"}/>;
  }
  if(size === "large"){
    return <LargeCoupon secondaryColor="gold" color="orangered" isSelected={isSelected} onClick={onClick}/>;
  }
  return <MediumCoupon secondaryColor={"gold"} color="orangered"
    defaultText={"No se Propone"} />;
}
