import React from "react";

const getStringValue = (mask, value) =>{
  return mask ? mask + " " + value : value;
};

const valueHasChanged = props => props.isAdjusted && props.previousValue !== props.newValue;

export const AdjustedValue = (props) => {
  const valorFueAjustado = valueHasChanged(props);
  return (
    <div className={props.className}>
      <p className={`valor ${valorFueAjustado ? "anterior" : ""}` }> { getStringValue(props.textMask, props.previousValue) } </p>
      { valorFueAjustado && <p className={"valor ajustado"}> { getStringValue(props.textMask, props.newValue) } </p> }
    </div>
  );
};

