import React from "react";

import Propuesta from "./Propuesta";
class Application extends React.Component {
  render () {
    return (
      <React.Fragment>
        <Propuesta nombre="Esteban" porcentaje="10%"/>
      </React.Fragment>
    );
  }
}

export default Application;
