import {Band} from "../models/Band";
import {getBackendApi} from "../api/BackendApiProvider";
import {sortBy} from "lodash";

export class BandsRepository {
  static cachedBands = [];

  static initialize = () => {
    return getBackendApi()
      .getBands()
      .then(rawBands => {
        this.cachedBands = rawBands.map(rawBand => new Band(rawBand));
        return this.cachedBands;
      });
  };

  static getCurrentBands(){
    return this.cachedBands;
  }

  static getBandByName(bandName) { return this.cachedBands.find(band => band.getName() === bandName);}

  static getBandForRate(rate) {
    return this.getSortedBandsDesc().find(band => rate >= band.getFloor());
  }

  static getSortedBandsDesc() {
    return this.getSortedBandsAsc().reverse();
  }

  static getSortedBandsAsc() {
    return sortBy(this.cachedBands, band => band.getFloor());
  }
}