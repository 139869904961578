import React from "react";
import {toast} from "react-toastify";
import Loading from "../sharedComponents/Loading";
import ProposalList from "./ProposalList";
import {BandsRepository} from "../businessLogic/repositories/BandsRepository";
import {RevisionsRepository} from "../businessLogic/repositories/RevisionsRepository";
import {PinesRepository} from "../businessLogic/repositories/PinesRepository";

class ProposalListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isLoading: true};
  }

  componentDidMount() {
    this.initializeState();
  }

  initializeState = async (revision) => {
    try {
      // Esto se ejecuta primero porque se cachea ya que es necesario para instanciar el resto de los objetos. Si no se hiciera asi, dichas instanciaciones deberían ser asincrónicas
      await BandsRepository.initialize();
      const currentRevision = revision ? await RevisionsRepository.getRevision(revision,true) : await RevisionsRepository.getCurrentRevision(true);
      const pines = currentRevision.pines;
      const revisions = RevisionsRepository.getRevisions();
      this.setState({
        pines: pines,
        currentRevision: currentRevision,
        revisions: await revisions,
        isLoading: false,
        isExtraordinaryRevisionOpen: currentRevision.getIsExtraordinary() && currentRevision.getIsOpen(),
      });
    } catch (error) {
      console.log(error);
      toast.error("Hubo un error al inicializar la revisión", {position: toast.POSITION.BOTTOM_RIGHT,});
      this.setState({isLoading: false});
    }
  };

  replacePine = (newPine, successMessage) => {
    this.setState({pines: this.state.pines.map(pine => pine.getId() === newPine.getId() ? newPine : pine)});
    successMessage && toast.success(successMessage, {position: toast.POSITION.BOTTOM_RIGHT,});
  };

  handleWeakSponsorToggle = (pine, currentPine) => {
    const updateSponsorPromise = !pine.isPineWeakSponsor(currentPine) ? pine.addWeakSponsor(currentPine) : pine.removeWeakSponsor(currentPine);
    updateSponsorPromise.then(returnedPine => this.replacePine(returnedPine));
  };

  onAddProposal = async (proposalArgs) => {
    if (!proposalArgs.pine.isTheSameProposal(proposalArgs.sponsors, proposalArgs.percentage, proposalArgs.selectedBand, proposalArgs.description)) {
      const sponsorPines = await PinesRepository.getPinesByNames(proposalArgs.sponsors, this.state.currentRevision);
      const sponsors = sponsorPines.map(pine => pine.getRawBasicData());
      await proposalArgs.pine.addProposal(sponsors, proposalArgs.percentage, proposalArgs.selectedBand, proposalArgs.description, this.state.currentRevision);
      if (!this.state.currentRevision.getCurrentProposedPineId()) {
        await this.state.currentRevision.setCurrentProposedPineId(proposalArgs.pine.getId());
      }
      this.replacePine(proposalArgs.pine, "Propuesta subida con éxito!");
    }
  };

  onRevisionSelection = (selectedRevision) => {
    this.setState({isLoading: true});
    this.initializeState(selectedRevision);
  };

  onExtraordinaryRevisionCreate = () => {
    this.setState({isLoading: true});
    return RevisionsRepository.createExtraordinaryRevision().then((revision) => {
      this.setState({isExtraordinaryRevisionOpen: true});
      this.initializeState(revision);
    });
  };

  onExtraordinaryRevisionSave = (impactDate) => {
    this.setState({isLoading: true});
    return RevisionsRepository.saveExtraordinaryRevision(this.state.currentRevision.getId(), impactDate).then(() => {
      this.setState({isExtraordinaryRevisionOpen: false});
      this.initializeState();
    }).catch(error => {
      console.log(error);
      toast.error("Hubo un error al guardar la revisión extraordinaria", {position: toast.POSITION.BOTTOM_RIGHT,});
    });
  };

  onExtraordinaryRevisionDelete = () => {
    this.setState({isLoading: true});
    RevisionsRepository.deleteRevision(this.state.currentRevision.getId()).then(() => {
      this.setState({isExtraordinaryRevisionOpen: false});
      this.initializeState();
    }).catch(error => {
      console.log(error);
      toast.error("Hubo un error al crear la revisión extraordinaria", {position: toast.POSITION.BOTTOM_RIGHT,});
    });
  };

  render() {
    if (this.state.isLoading)
      return <Loading/>;
    return <ProposalList currentRevision={this.state.currentRevision}
      revisions={this.state.revisions}
      pines={this.state.pines}
      replacePine={this.replacePine}
      onAddProposal={this.onAddProposal}
      handleWeakSponsorToggle={this.handleWeakSponsorToggle}
      onRevisionSelection={this.onRevisionSelection}
      onExtraordinaryRevisionCreate={this.onExtraordinaryRevisionCreate}
      onExtraordinaryRevisionSave={this.onExtraordinaryRevisionSave}
      isExtraordinaryRevisionOpen={this.state.isExtraordinaryRevisionOpen}
      onExtraordinaryRevisionDelete={this.onExtraordinaryRevisionDelete}
    />;
  }
}

export default ProposalListContainer;
