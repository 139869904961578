import React from "react";
import PineItem from "./PineItem";

export const PinesList = props => (
  <div className="pines-list">
    {
      props.pines.map((pine, key) =>
        <PineItem
          pine={pine}
          key={key}
          pines={props.pines}
          allPines={props.allPines}
          onWeakSponsorToggle={props.onWeakSponsorToggle}
          onAddProposal={props.onAddProposal}
          shouldItemsByReadOnly={props.shouldItemsByReadOnly}
          revisionData={props.revisionData}
        />
      )
    }
  </div>
);
