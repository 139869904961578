import React from "react";
import {withRouter} from "react-router-dom";
import {TextField} from "@material-ui/core";
import {AdjustedPines} from "./AdjustedPines";
import {AdjustedBenefits} from "./AdjustedBenefits";
import {AdjustedBands} from "./AdjustedBands";
import {RevisionsRepository} from "../businessLogic/repositories/RevisionsRepository";
import routes from "../Routes";
import CloseRevisionButton from "../revisionsPage/CloseRevisionButton";
import Loading from "../sharedComponents/Loading";
import {Select} from "../sharedComponents/Select";

const container = {
  fontSize: "1rem",
  height: "100%",
  overflowY: "scroll",
};

const listsContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
};

const headerStyle = {
  background: "white",
  borderRadius: "30px 30px 0 0 ",
  marginBottom: "2em",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  padding: "1em 3em",
};

const actionsContainerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  width: "79%",
  justifyContent: "space-between",
};

const otherAdjustmentsContainerStyle = {
  display: "flex",
  flexDirection: "column",
  paddingRight: "2rem",
  paddingLeft: "2rem",
  borderRadius: "10px",
  background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
  boxShadow: "2px 2px 7px #bababa",
};

export const calculatedAdjustedValue = (oldValue, adjustmentPercentage) => {
  return Math.ceil(parseInt(oldValue) * parseFloat(adjustmentPercentage) / 100 + parseInt(oldValue));
};

export const calculateAdjustmentFromPercentage = (oldValue, adjustmentPercentage) => {
  const parsedValue = parseInt(oldValue);
  return (100 * calculatedAdjustedValue(parsedValue, adjustmentPercentage) / parsedValue - 100).toFixed(2);
};

class AdjustmentPage extends React.Component {
  closeAdjustment = (impactMonth) => {
    const excludedPinesId = this.state.excludedPines.map((pineName) => this.props.pines.filter(aPine => aPine.name === pineName)[0].id);
    this.setState({isLoading: true});
    return RevisionsRepository.createAdjustmentRevision(this.state.selectedPercentage, impactMonth,excludedPinesId)
      .then(() => this.props.history.push(routes.proposals))
      .catch(() => this.setState({isLoading: false}));
  };
  getRateAfterAdjusment = (pine) => {
    return calculatedAdjustedValue(pine.rate, this.state.selectedPercentage);
  };

  constructor(props) {
    super(props);
    this.state = {selectedPercentage: 0, isLoading: false,excludedPines:[]};
  }

  onPercentageChange = (event) => {
    const value = event.target.value;
    this.setState({selectedPercentage: value});
  };

  hasAdjustmentPercentageChanged = () => {
    return !!this.state.selectedPercentage;
  };

  shouldDisableCloseRevisionButton = () => {
    return this.invalidAdjustment() || this.props.isThereAnyOpenExtraordinaryRevision;
  };

  handleExcludedPines = (event) => {
    this.setState({excludedPines: event.target.value});
  };


  render() {
    if (this.state.isLoading)
      return <Loading/>;
    const selectedPercentage = parseFloat(this.state.selectedPercentage);
    //TODO: Pasar esto a que lo haga por ID en vez de hacer esto
    const notExcludedPines = this.props.pines.filter((pine) => !this.state.excludedPines.includes(pine.name));

    return (
      <div style={container}>
        <div style={headerStyle}>
          <div style={actionsContainerStyle}>
            <h1 style={{fontWeight: "200"}}> Crear Ajuste </h1>

            <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "center"}}>
              <div style={{marginRight: "1rem"}}>
                <h4 style={{marginBottom: "0.5rem"}}>Pines Excluides</h4>
                <Select
                  value={this.state.excludedPines}
                  multiple
                  onChange={this.handleExcludedPines}
                  options={this.props.pines}
                  style={{width: "23rem", minHeight: "3.5rem", borderRadius: "15px"}}
                />
              </div>
              <div>
                <h4 style={{marginBottom: "0.5rem"}}>Porcentaje de Ajuste</h4>
                <TextField
                  style={{backgroundColor: "white", width: "10rem", marginRight: "1rem", borderRadius: "15px"}}
                  id="filled-textarea"
                  label={"%"}
                  value={this.state.selectedPercentage}
                  onChange={this.onPercentageChange}
                  variant="filled"
                />
              </div>

              <CloseRevisionButton
                handleCloseRevision={this.closeAdjustment}
                pines={this.props.pines}
                buttonLabel={"Crear Ajuste"}
                pinesToExport={notExcludedPines}
                getPineNewRate={(pine) => this.getRateAfterAdjusment(pine)}
                disabledText={"El porcentaje no es válido o hay alguna revisión extraordinaria abierta"}
                shouldBeDisabled={this.shouldDisableCloseRevisionButton}
                textToShow={this.textToShowInPopUp}
                revisionTitle={"Ajuste por inflacion -"}
              />
            </div>
          </div>
        </div>
        <div style={listsContainerStyle}>
          <AdjustedPines excludedPines={this.state.excludedPines} isAdjusted={this.hasAdjustmentPercentageChanged()} selectedPercentage={selectedPercentage} pines={this.props.pines}/>
          <div style={otherAdjustmentsContainerStyle}>
            <h3> Números afectados por el ajuste </h3>
            <div style={{display: "flex", flexDirection: "row"}}>
              <AdjustedBands isAdjusted={this.hasAdjustmentPercentageChanged()} selectedPercentage={selectedPercentage} pines={this.props.pines}/>
              <AdjustedBenefits isAdjusted={this.hasAdjustmentPercentageChanged()} selectedPercentage={selectedPercentage} pines={this.props.pines}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  textToShowInPopUp=() => {
    return <span style={{fontSize: "1.1rem"}}>
      {"¿Estas seguro de querer hacer un ajuste por " + this.state.selectedPercentage + "% ? "}
    </span>;
  };

  invalidAdjustment= () => {
    return parseFloat(this.state.selectedPercentage) <= 0;
  };
}

export default withRouter(AdjustmentPage);
