import React from "react";
import {Logo} from "../sharedComponents/information/Logo";
import PageDial from "./PageDial";
import CurrentLoggedInPineSection from "./CurrentLoggedInPineSection";

export const Header = () => (
  <nav className={"header-container"}>
    <Logo/>
    <PageDial/>
    <CurrentLoggedInPineSection/>
  </nav>
);
