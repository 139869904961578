import {getBackendApi} from "../api/BackendApiProvider";
import {faCheckCircle, faExclamationCircle, faMinusCircle} from "@fortawesome/free-solid-svg-icons";

export const proposalStatuses = {
  ACCEPTED: {name: "Propuesta Aceptada", backendName: "aceptada", icon: faCheckCircle, enabledColor: "green"},
  PRE_CONFIRMED: {name: "Propuesta Pre Confirmada", backendName: "pre_confirmada", icon: faCheckCircle, enabledColor: "green"},
  ON_HOLD: {name: "Propuesta Iterar", backendName: "iterar", icon: faExclamationCircle, enabledColor: "goldenrod"},
  NOT_TREATED: {name: "Propuesta No Tratada", backendName: "no_tratada", icon: faCheckCircle, enabledColor: "grey"},
  NOT_COMPLETED: {name: "Propuesta No Completada", backendName: "no_completada", icon: faMinusCircle, enabledColor: "lightgrey"}
};

//TODO: Reemplazar por manejo automático de keys
const statusesAsArray = [proposalStatuses.ACCEPTED, proposalStatuses.NOT_TREATED, proposalStatuses.ON_HOLD, proposalStatuses.PRE_CONFIRMED, proposalStatuses.NOT_COMPLETED];

export class ProposalsRepository {

  static getProposalStatusByBackendName(statusName) {
    return statusesAsArray.find(status => status.backendName === statusName);
  }

  static getProposalStatusByFrontendName(statusName) {
    return statusesAsArray.find(status => status.name === statusName);
  }

  static createProposal(rawProposalData) {
    return getBackendApi().createProposal(rawProposalData);
  }

  static isInInvalidDecisionForSavingProposal(selectedDecision) {
    return selectedDecision && selectedDecision.backendName === proposalStatuses.NOT_TREATED.backendName;
  }
}
