import React from "react";
import {PercentageSquare} from "../sharedComponents/information/RiseSquare";
import {strategyFromPine} from "../sharedComponents/riseStrategies";

const calculateRiseLabel = (pine) => {
  return pine.getProposalPercentage();
};

const shouldShowEmptySquare = (props) => {
  return !props.pine.hasProposal() && props.percentage === undefined;
};

export function Coupon(props) {
  const {pine} = props;
  if(shouldShowEmptySquare(props)){
    return <PercentageSquare/>;
  }

  const CouponFromStrategy = strategyFromPine(pine).coupon;
  return <CouponFromStrategy {...props} percentage={props.percentage || calculateRiseLabel(pine)} bandState={{selectedBand: pine.getProposalBand()} } />;
}