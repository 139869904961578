import React from "react";
import {AdjustedValue} from "../sharedComponents/information/AdjustedValue";
import {calculatedAdjustedValue} from "./AdjustmentPage";
import {BandsRepository} from "../businessLogic/repositories/BandsRepository";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  width: "15rem",
  marginRight: "2rem"
};

const lineContainerStyle = {
  display: "flex",
  height: "2.6rem",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  borderTop: "1px solid lightgrey"
};

export const AdjustedBands = props => {
  const {selectedPercentage} = props;
  const bands = BandsRepository.getCurrentBands();
  return (
    <div style={containerStyle}>
      <h4> Franjas </h4>
      {bands.map(band =>
        <div key={band.getName()} style={lineContainerStyle}>
          <span style={{width: "11rem"}}> {band.getName()} </span>
          <AdjustedValue isAdjusted={props.isAdjusted} textMask={"$"} previousValue={band.getFloor()} newValue={calculatedAdjustedValue(band.getFloor(), selectedPercentage)}/>
        </div>
      )}
    </div>
  );
};
