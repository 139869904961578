import React from "react";
import {Header} from "./header/Header";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContainer from "./AuthContainer";

import {BrowserRouter, Route, Switch} from "react-router-dom";
import routes from "./Routes";
import ProposalListContainer from "./proposalsPage/ProposalListContainer";
import AdjustmentPageContainer from "./adjustmentsPage/AdjustmentPageContainer";
import ProposalsReviewPageContainer from "./revisionsPage/ProposalsReviewPageContainer";
import PinesPageContainer from "./pinesPage/PinesPageContainer";


export class App extends React.Component {

  render() {
    return (
      <AuthContainer>
        <BrowserRouter>
          <div className={"app-wrapper"}>
            <Header/>
            <div className={"container"}>
              <div className={"sub-container"}>
                <Switch>
                  <Route exact path={routes.proposals} render={() =>
                    <ProposalListContainer/>}
                  />
                  <Route exact path={routes.proposalReviews} render={() =>
                    <ProposalsReviewPageContainer/>}
                  />
                  <Route exact path={routes.adjustmentRevision} render={() =>
                    <AdjustmentPageContainer/>}
                  />
                  <Route exact path={routes.pines} render={() =>
                    <PinesPageContainer/>}
                  />
                </Switch>
              </div>
            </div>
            <ToastContainer/>
          </div>
        </BrowserRouter>
      </AuthContainer>

    );
  }
}
