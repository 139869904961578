import {ProposalsRepository, proposalStatuses} from "../repositories/ProposalsRepository";
import {BandsRepository} from "../repositories/BandsRepository";
import {getBackendApi} from "../api/BackendApiProvider";

export class Proposal {

  constructor(rawProposalData) {
    this.id = rawProposalData.id;
    this.sponsors = rawProposalData.sponsors;
    this.description = rawProposalData.descripcion;
    this.weakSponsors = rawProposalData.sponsorsDebiles;
    this.date = new Date(rawProposalData.fecha);
    this.rate = rawProposalData.rate;
    this.status = ProposalsRepository.getProposalStatusByBackendName(rawProposalData.status);
    this.percentage = rawProposalData.porcentaje;
    this.band = BandsRepository.getBandByName(rawProposalData.franja);
    this.rate = rawProposalData.rate;
    this.salary = rawProposalData.bruto;
    this.reviewComments = rawProposalData.comentariosRevision;
    this.reviewPercentage = rawProposalData.porcentajeRevision;
    this.reviewRate = rawProposalData.rateRevision;
    this.rawProposalData = rawProposalData;
  }

  getId() {
    return this.id;
  }

  isEmpty() {
    return !this.rawProposalData.date; // Esto lo podríamos sacar cuando dejemos de tener los mocks
  }

  getDate() {
    return this.date;
  }

  getRawProposalData() {
    return this.rawProposalData;
  }

  getWeakSponsors() {
    return this.weakSponsors || [];
  }

  getAmountOfWeakSponsors() {
    return this.getWeakSponsors().length;
  }

  getProposedRate(){
    return this.getReviewRate() || this.getRate();
  }

  getReviewRate() {
    return this.reviewRate;
  }

  getRate(){
    return this.rate;
  }

  getReviewComments(){
    return this.reviewComments || "";
  }

  isProposalAccepted() {
    return this.isEqualToProposalStatus(proposalStatuses.ACCEPTED);
  }

  isProposalPreConfirmed() {
    return this.isEqualToProposalStatus(proposalStatuses.PRE_CONFIRMED);
  }

  isProposalOnHold() {
    return this.isEqualToProposalStatus(proposalStatuses.ON_HOLD);
  }

  isProposalNotTreated() {
    return this.isEqualToProposalStatus(proposalStatuses.NOT_TREATED);
  }

  isNotCompleted(){
    return this.isEqualToProposalStatus(proposalStatuses.NOT_COMPLETED);
  }

  isEqualToProposalStatus(proposalStatus) {
    return this.status && this.status.name === proposalStatus.name;
  }

  getSponsors() {
    return this.sponsors || [];
  }

  getSponsorsNames() {
    return this.getSponsors().map(sponsor => sponsor["nombre"]);
  }

  getBand() {
    return this.band;
  }

  getDescription() {
    return this.description;
  }

  getPercentage() {
    return this.percentage;
  }

  getReviewPercentage() {
    return this.reviewPercentage;
  }

  addWeakSponsor(pineSponsor){
    let self = this;
    return getBackendApi()
      .addWeakSponsor(this.getId(), pineSponsor.getId())
      .then((response) => {
        self.weakSponsors = response.data ;
        self.rawProposalData.sponsorsDebiles = response.data ;
      });
  }

  getStatus(){
    return this.status;
  }

  removeWeakSponsor(pineSponsor){
    let self = this;
    return getBackendApi()
      .removeWeakSponsor(this.getId(), pineSponsor.getId())
      .then((response) => {
        self.rawProposalData.sponsorsDebiles = response.data ;
        this.weakSponsors = response.data ;
      })
    ;
  }

  isRecategorization(percentage, pine) {
    return pine.getBand() !== this.getBand();
  }
}