import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

const popupContainerStyle = {
  background: "linear-gradient(to right, #d8e1ed 0%, #e3eee1 100%)",
  padding: "2rem",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  alignitems: "center",
  justifyContent: "space-between",
};

const titleStyle = {
  fontSize: "1.2rem",
};

const contentContainerStyle = {
  width: "100%",
  marginTop: "1rem",
};

const actionContainerStyle = {
  width: "100%",
  display: "flex",
  marginTop: "2rem",
  flexDirection: "row",
  alignItems: "space-between",
  justifyContent: "flex-end"
};

export const PopUpButton = (props) => {
  const handleOk = () => {
    if(props.handleOk) props.handleOk();
    handleClose();
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const disabledTitle = () => {
    return props.disabled ? props.disabledTitle : "";
  };

  const buttonDisabledTitle = () => {
    return props.buttonDisabled ? "Próximamente" : "";
  };

  return <div title={buttonDisabledTitle()}>
    <Button onClick={handleClickOpen} color={"primary"} variant={"outlined"} disabled={props.buttonDisabled}>
      {props.textToShowInButton}
    </Button>
    {open && <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" open={open}
      PaperProps={{style: popupContainerStyle}}>
      <span style={titleStyle}> {props.textToShowInButton} </span>
      <div style={contentContainerStyle}> {props.textToShow} </div>
      <div style={actionContainerStyle}>
        <Button onClick={handleClose} variant={"outlined"} color={"secondary"} style={{marginRight: "2rem"}}> Cancelar </Button>
        <div style={{opacity: props.disabled() ? "0.4" : "1"}} title={disabledTitle()}>
          <Button disabled={props.disabled()} onClick={handleOk} variant={"outlined"} color="primary">
            {props.okChildren()}
          </Button>
        </div>
      </div>
    </Dialog>}

  </div>;

};

export default PopUpButton;
