import React, {useState} from "react";
import {Square} from "./Square";

export function CompactCoupon({defaultText,textOnHover, color, secondaryColor}) {
  const [inHover, setHover] = useState(false);

  return <Square color={color} secondaryColor={secondaryColor} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
    <span>
      {inHover ? textOnHover : defaultText}
    </span>
  </Square>;
}
