import React from "react";
import {ProposalStatusIcon} from "../icons/ProposalStatusIcon";
import Dropdown from "../Dropdown";


const titleFontStyle = {
  fontSize: "1.5rem",
  fontWeight: "700",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  marginLeft: "1.5rem",
  textAlign: "center"
};


const getPineWithProposals = (pines) => {
  return pines.filter(pine => pine.hasProposal());
};


export function ProposalDropdown(props) {

  const pinesWithProposals = getPineWithProposals(props.pines);
  const onRenderSelectedOption = (pine) => {
    return <React.Fragment>
      <ProposalStatusIcon pine={pine}/>
      <span style={titleFontStyle}> {pine.getName()} </span>
    </React.Fragment>;
  };
  const onShowOption = (pine) => {
    return <React.Fragment>
      <ProposalStatusIcon pine={pine}/>
      <span style={{...titleFontStyle, fontSize: "1.2rem"}}> {pine.getName()} </span>
    </React.Fragment>;
  };
  return (<Dropdown options={pinesWithProposals} onSelected={props.onChangeProposedPine} selected={props.proposedPine}
    onRenderSelectedOption={onRenderSelectedOption} onShowOption={onShowOption}/>);
}

export default ProposalDropdown;
