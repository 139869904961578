import React from "react";
import {TextField} from "@material-ui/core";

const style = {
  justifyContent: "space-between",
  display: "flex",
  alignItems: "center",
  marginBottom: "1rem"
};

export const InputNewPineModal = ({name, value, onChange}) => {
  return <div style={style}>
    <b>{name}</b>
    <TextField
      type="text"
      value={value}
      onChange={onChange}
    />
  </div>;
};

export const InputDateNewPineModal = ({value,onChange}) => {
  return <div style={style}>
    <b> Fecha de Ingreso: </b>
    <TextField
      type="date"
      value={value}
      onChange={onChange}
    />
  </div>;
};
