import React from "react";
import {AdjustedValue} from "./AdjustedValue";

export const ProposalData = (props) => {
  const newValue = props.pine.getProposalValueByKey(props.valueKey);
  const oldValue = props.shouldHideDifference ? newValue : props.pine.getOldProposalValueByKey(props.valueKey) ;
  return <AdjustedValue textMask={props.textMask}
    isAdjusted={props.pine.hasProposal()}
    previousValue={oldValue}
    newValue={newValue}
    className={props.className}
  />;
};
