export const isEmpty = (obj) => !obj || Object.keys(obj).length === 0;

export const revertArrayNonDestructively = (array) => {
  return [...array].reverse();
};

export const formatDateToMonthAndYearInWords = (stringDate) => {
  return new Date(stringDate).toLocaleDateString("es-AR", { year: "numeric", month: "long"});
};

export const formatDateToMonthYear = (stringDate) => {
  return new Date(stringDate).toLocaleDateString("es-AR", { year: "numeric", month: "numeric"});
};

export const parseNumberToMoneyString = (number) => {
  return "$" + number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const today = () => {
  const date = new Date();
  return date.getFullYear() + "-" + (+date.getMonth() + 1) + "-" + date.getDate();
};

const isTheLastElement =(collection,index)  => {
  return index === collection.length - 1;
};

export const getNextIndex = (collection, index) => {
  return isTheLastElement(collection, index) ? index : index + 1;
};

const isTheInitialElement = (index) => {
  return index === 0;
};
export const getPreviousIndex = (collection, index) => {
  return isTheInitialElement(index) ? index : index - 1;
};

export const actualPath = () => {
  return window.location.pathname;
};

export const sortPinesByRate=  (pines) => {
  return pines.sort((pine, anotherPine) =>
    anotherPine.getMostUpdatedRate() - pine.getMostUpdatedRate()
  );
};

export const isMediumScreenOrLess = () => window.innerWidth < 1500;
