import React from "react";
import {CanvasJSChart} from "../../../canvasjs.react";
import {revertArrayNonDestructively} from "../commons";
import {Spinner} from "../Spinner";
import moment from "moment";
import _ from "lodash";

const chartStyle = {
  width: "35rem"
};

const chartContainerStyle = {
  width: "550px",
  height: "250px",
  padding: "1rem",
  borderRadius: "10px",
  background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
  boxShadow: "2px 2px 7px #bababa",
};

export class PineLastRisesChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {selectedProposal: null, loading: true};
  }

  componentDidMount() {
    this.props.pine.getRises().then(response => {
      this.setState({lastRises: response, loading: false});
    });
  }

  getRisesDataPoints = (includeAdjustments) => {
    if(_.isEmpty(this.state.lastRises)){
      return [];
    }
    const revertedRises = revertArrayNonDestructively(this.state.lastRises);

    const realRises = this.state.lastRises.filter(rise => !rise.esAjuste);
    const initialDateToDraw = this.getInitialDateToDraw(realRises,this.props.pine.startDate);
    const initialValue = [{x: initialDateToDraw, y: 0}];

    const lastRise = revertedRises[revertedRises.length - 1];
    let lastNonAdjustmentRiseIndex = 0;
    const filteredRises = includeAdjustments ? revertedRises : revertedRises.filter(rise => !rise["esAjuste"]);
    const dataPoints = filteredRises.map((rise, index) => {
      if(!rise["esAjuste"]) lastNonAdjustmentRiseIndex = index;
      return {x: new Date(rise["fecha"]), y: this.calculatePercentageUntil(filteredRises, index)};
    });

    let resultArray = initialValue.concat(dataPoints);
    // If adjustments are not included, we still need to end at the same spot in order the graph to be nice
    if(!includeAdjustments && lastRise["esAjuste"]) {
      return resultArray.concat({x: new Date(lastRise["fecha"]), y: this.calculatePercentageUntil(filteredRises, lastNonAdjustmentRiseIndex)});
    } else {
      return resultArray;
    }

  };

  getInitialDateToDraw(realRises,pineStartDate) {
    const firstRise = _.last(realRises);
    if(firstRise){
      const lastProposalBeforeEntry = moment(firstRise.fecha).clone();
      lastProposalBeforeEntry.subtract(4, "month");
      return lastProposalBeforeEntry;
    }
    return moment(pineStartDate);
  }

  calculatePercentageUntil = (rises, riseIndex) => {
    return rises.slice(0, riseIndex + 1).reduce((acc, rise) => {
      const percentage = parseInt(rise["porcentaje"]);
      const percentageOverAcc = percentage*acc / 100; // esto se hace para calcular el porcentaje del acumulado. Al no sumarse simplemente los porcentajes, se debe considerar que si aumento 7% del sueldo, y tengo otra revisiones, no aumento realmente 7% en el grafico, sino que aumento el 7% + el 7% de los porcentajes acumulados!
      return percentage + acc + percentageOverAcc;
    }, 0);
  };

  getGraphOptions = (props) => {
    return {
      theme: "light2",
      colorSet: "colorSet2",
      backgroundColor: "rgba(0,0,0,0)",
      title: {
        text: "Evolución histórica",
        fontFamily: "'Lato', sans-serif",
        fontWeight: "200",
      },
      width: props.width,
      height: props.height,
      subtitles: [{
        text: "Aumento acumulado sobre aumentos por performance"
      }],
      axisY: {
        includeZero: true,
        prefix: ""
      },
      toolTip: {
        shared: true
      },
      data: [
        {
          type: "area",
          name: "Acumulado incluyendo Ajustes",
          showInLegend: true,
          xValueFormatString: "MMM YYYY",
          dataPoints: this.getRisesDataPoints(true)
        },
        {
          type: "area",
          name: `Camino de ${this.props.pine.name}`,
          showInLegend: true,
          xValueFormatString: "MMM YYYY",
          dataPoints: this.getRisesDataPoints(false)
        }
      ]
    };
  };

  render() {
    if (this.state.loading) return <Spinner />;
    return (
      <div style={chartContainerStyle}>
        <CanvasJSChart style={chartStyle} options={this.getGraphOptions(this.props)}/>
      </div>
    );
  }
}
