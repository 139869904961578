import React from "react";
import {AdjustedValue} from "../sharedComponents/information/AdjustedValue";
import {calculateAdjustmentFromPercentage, calculatedAdjustedValue} from "./AdjustmentPage";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  paddingRight: "2rem",
  paddingLeft: "2rem",
  width: "40rem",
  marginRight: "2rem",
  borderRadius: "10px",
  background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
  boxShadow: "2px 2px 7px #bababa",
};

const lineContainerStyle = {
  display: "flex",
  height: "2.6rem",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  borderTop: "1px solid silver",
};

const pineNameStyle = {
  fontWeight: "700",
  fontSize: "1.1rem",
  width: "20rem",
};

const genericSpanStyle = {
  color: "green",
  fontSize: "1.1rem",
  fontWeight: "700"
};


export const AdjustedPines = props => {
  const {pines, selectedPercentage,excludedPines} = props;
  return (
    <div style={containerStyle} >
      <h3> Pinos </h3>
      {pines.map(pine =>
      {
        const isExcluded = excludedPines.includes(pine.getName());
        return<div key={pine.getId()} style={lineContainerStyle}>
          <span style={pineNameStyle}> {pine.getName()} </span>
          <AdjustedValue isAdjusted={props.isAdjusted && !isExcluded} textMask={"$"} previousValue={pine.getOldRate()} newValue={calculatedAdjustedValue(pine.getOldRate(), selectedPercentage)}/>
          <AdjustedValue isAdjusted={props.isAdjusted && !isExcluded} textMask={"$"} previousValue={pine.getSalary()} newValue={calculatedAdjustedValue(pine.getSalary(), selectedPercentage)}/>
          <span style={genericSpanStyle}> {calculateAdjustmentFromPercentage(pine.getOldRate(), selectedPercentage)  + "%"}  </span>
        </div>;
      })}
    </div>
  );
};
