import React from "react";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {Icon} from "../sharedComponents/icons/Icon";
import {withAuthContext} from "../AuthContainer";
import {getBackendApi} from "../businessLogic/api/BackendApiProvider";

export class CurrentLoggedInPineSection extends React.Component {

  onLogoutClick = async () => {
    await getBackendApi().logout();
    window.location.href = "/";
  };

  render() {
    return (
      <div className={"current-logged-in-pine-section"}>
        <p className={"name"}> {this.props.loggedPine?.name} </p>
        <div className={"logout"} onClick={this.onLogoutClick} title={"Logout"}>
          <Icon icon={faSignOutAlt} borderColor={"black"} />
        </div>
      </div>
    );
  }
}

export default withAuthContext(CurrentLoggedInPineSection);
