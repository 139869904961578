import React from "react";

import Button from "@material-ui/core/Button";
import ExtraordinaryRevisionWarningIcon from "./ExtraordinaryRevisionWarningIcon";
import {CloseRevisionButton} from "../revisionsPage/CloseRevisionButton";

const containerStyle = {
  marginRight: "1em"
};

const buttonsContainerStyle = {
  display: "flex",
  alignItems: "center"
};

export const ExtraordinaryRevisionButton = (props) => {

  const shouldDisableCloseRevision = () => {
    return !props.pines.some((pine) => pine.hasAProposalThatChangeRate());
  };

  const confirmedPines = props.pines.filter(pine => pine.hasAProposalThatChangeRate());

  return <div style={containerStyle}>
    { !props.isExtraordinaryRevisionOpen ?
      <Button variant="outlined" color="primary" onClick={() => props.onExtraordinaryRevisionCreate()}> Crear Revisión Extraordinaria </Button>
      :
      <div style={buttonsContainerStyle}>
        <ExtraordinaryRevisionWarningIcon/>
        <CloseRevisionButton
          buttonLabel={"Guardar Revisión Extraordinaria"}
          handleCloseRevision={ props.onExtraordinaryRevisionSave } pines={props.pines}
          pinesToExport={confirmedPines} textToShow={() => ""}
          shouldBeDisabled={() => shouldDisableCloseRevision()}
          disabledText={"No se puede cerrar una revisión extraordinaria sin propuestas"}
          revisionTitle={"Revision de rates Extraordinaria- "}
          getPineNewRate={(pine) => pine.getNewRateOfPine()}
        />
        <Button style={{marginLeft: "1rem"}} variant={"outlined"} onClick={props.onExtraordinaryRevisionDelete}> Descartar Cambios </Button>
      </div>
    }
  </div>;

}