import React from "react";
import {RiseSquare} from "../information/RiseSquare";
import {faArrowCircleUp, faMoneyCheckAlt, faWrench} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextField from "@material-ui/core/TextField";
import {formatDateToMonthYear} from "../commons";
import {Spinner} from "../Spinner";
import {PineLastRisesChart} from "./PineLastRisesChart";

const bodyContainerStyle = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  padding: "0 2rem",
  alignItems: "center",
};

const risesListStyle = {
  width: "20rem",
  height: "5em",
  overflowY: "scroll",
  marginLeft: "2rem",
  marginTop: "1.7rem"
};

const riseLineContainerStyle = {
  borderTop: "1px solid black",
  height: "4rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start"
};

const selectedProposalContainerStyle = (shouldShow) => ({
  background: "whitesmoke",
  borderLeft: shouldShow ? "1px solid silver" : "",
  borderTop: "1px solid silver",
  borderRadius: "10px",
  paddingLeft: shouldShow ? "2rem" : 0,
  paddingTop: "1rem",
  width: shouldShow ? "20rem" : 0,
  display: "flex",
  flexDirection: "column",
  opacity: shouldShow ? "1" : 0,
  transition: "all 0.8s ease",
  overflow: "hidden"
});

const proposalIconStyle = {
  height: "2rem",
  width: "2rem",
  cursor: "pointer",
  marginRight: "1rem"
};

const textFieldStyle = {
  background: "silver",
  borderRadius: "10px",
  width: "18rem",
  marginBottom: "1rem"
};

const riseTypeIconStyle = {
  height: "1.5rem",
  width: "1.5rem",
  marginLeft: "1rem"
};

export class PineLastRises extends React.Component {

  constructor(props) {
    super(props);
    this.state = {selectedProposal: null, loading: true};
  }

  componentDidMount() {
    this.props.pine.getRises().then(response => {
      this.setState({lastRises: response, loading: false});
    });
  }

  getProposalFieldOr(proposalField, defaultValue) {
    //This is because we're mounting the section anyway to reduce the animation code overhead
    return this.state.selectedProposal ? this.state.selectedProposal[proposalField] : defaultValue;
  }

  onProposalClick = async (event) => {
    const rise = this.state.lastRises.find(rise => rise["fecha"] === event.currentTarget.id);
    this.setState({selectedProposal: !this.state.selectedProposal || this.state.selectedProposal["fecha"] !== rise["fecha"] ? rise : null});
  };

  render() {
    if (this.state.loading) return <Spinner />;
    return (
      <div style={bodyContainerStyle}>
        <PineLastRisesChart pine={this.props.pine} width={"600"} height={"300"}/>
        <div style={risesListStyle}>
          <div style={{marginBottom: "1rem"}}>
            <b> Últimos 5 aumentos </b>
          </div>
          {this.state.lastRises.map(rise => {
            let isAdjustment = rise["esAjuste"];
            return <div style={riseLineContainerStyle} key={rise["fecha"]}>
              <span
                style={{width: "4rem"}}> {formatDateToMonthYear(rise["fecha"])} </span>
              {isAdjustment ?
                <div title={"Ajuste"} style={{marginRight: "1rem"}}>
                  <FontAwesomeIcon style={riseTypeIconStyle} icon={faWrench} color={"#5C648A"}/>
                </div>
                :
                <div title={"Aumento"} style={{marginRight: "1rem"}}>
                  <FontAwesomeIcon style={riseTypeIconStyle} icon={faArrowCircleUp}
                    color={"green"}/>
                </div>
              }
              <RiseSquare percentage={rise["porcentaje"]} pine={this.props.pine} isAdjustment={isAdjustment}/>
              <FontAwesomeIcon style={proposalIconStyle} icon={faMoneyCheckAlt} color={"black"}
                onClick={this.onProposalClick} id={rise["fecha"]}/>
            </div>;
          }
          )}
        </div>
        <div style={selectedProposalContainerStyle(!!this.state.selectedProposal)}>
          <b style={{width: "20rem", marginBottom: "1rem"}}> Detalles de la propuesta
                        del {formatDateToMonthYear(this.getProposalFieldOr("fecha", ""))} </b>

          <TextField style={textFieldStyle} readOnly label="Porcentaje" variant="filled"
            value={this.getProposalFieldOr("porcentaje", "") + "%"}/>
          <TextField style={textFieldStyle} readOnly label="Sponsors" variant="filled"
            value={(this.getProposalFieldOr("sponsors", null) || []).map(sponsor => sponsor["nombre"]).join(", ")}/>
          <TextField style={textFieldStyle} readOnly label="Franja" variant="filled"
            value={this.getProposalFieldOr("franja", "")}/>
          <TextField style={textFieldStyle} readOnly multiline maxRows={4} id="filled-basic" label="Descripción"
            variant="filled" value={this.getProposalFieldOr("descripcion", "")}/>

        </div>
      </div>
    );
  }
}
