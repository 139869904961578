import {Pine} from "../models/Pine";
import {
  getNextIndex,
  getPreviousIndex,
  revertArrayNonDestructively,
  sortPinesByRate
} from "../../sharedComponents/commons";
import {getBackendApi} from "../api/BackendApiProvider";
import {RevisionsRepository as RevisionRepository} from "./RevisionsRepository";

export class PinesRepository {

  static getPines(isReversed, revision) {
    return this.getPinesWithDefaultValuesForNulls(revision).then(pines => {
      return isReversed ? revertArrayNonDestructively(pines) : pines;
    });
  }

  static convertRawPines(revision) {
    return getBackendApi()
      .getPines(revision.id)
      .then(rawPines => rawPines.map(rawPine => new Pine(rawPine)))
      .then(sortPinesByRate);
  }

  static getRevision(revision) {
    return revision ? RevisionRepository.getRevision(revision) : RevisionRepository.getCurrentRevision();
  }

  static getPinesWithDefaultValuesForNulls(revision) {
    return this.convertRawPines(revision).then(pines => {
      return pines.map(p => {
        if(p.getProposal().isEmpty()){
          p.initializeProposal();
          return p;
        } else {
          return p;
        }
      });
    });
  }

  static getPineIndexByName(name, isReversed) {
    return this.getPines(isReversed).then(pines => pines.findIndex(pine => pine.getName() === name));
  }

  static getCurrentPine() {
    return getBackendApi()
      .getCurrentPine()
      .then((rawPine) => new Pine(rawPine));
  }

  static getPinesWithProposals(isReversed,revision) {
    return this.getPines(isReversed,revision).then(pines=> pines.filter(pine => pine.hasProposal()));
  }

  static getPinesByNames(names,revision) {
    return this.getPines(false,revision).then(pines => {
      return pines.filter(pine => names.includes(pine.getName()));
    });
  }

  static getProposedPineFor(currentProposedPineId,pinesWithPropopsals,getRequiredIndex){
    const reversedProposedPines = pinesWithPropopsals.filter(pine => pine.hasProposal());
    const currentProposedPineIndex = reversedProposedPines.findIndex(proposedPine => proposedPine.getId() === currentProposedPineId);
    const nextIndex = getRequiredIndex(reversedProposedPines,currentProposedPineIndex);
    return Promise.resolve(reversedProposedPines[nextIndex].getId());
  }

  static getNextProposedPineId(currentProposedPineId,pinesWithPropopsals) {
    return PinesRepository.getProposedPineFor(currentProposedPineId,pinesWithPropopsals,getNextIndex);
  }

  static getPreviousProposedPineId(currentProposedPineId,pinesWithPropopsals) {
    return PinesRepository.getProposedPineFor(currentProposedPineId,pinesWithPropopsals,getPreviousIndex);

  }

  static getLoggedPine(){
    return getBackendApi()
      .getLoggedPine()
      .then((rawPine) => new Pine(rawPine));
  }

  static closeActualRevision(impactDate) {
    return getBackendApi()
      .closeRevision(impactDate);
  }
}
