import Transition from "react-transition-group/Transition";
import React from "react";
import {isMediumScreenOrLess} from "./commons";

const containerStyle = (transition, height) => {
  const shouldShow = transition === "entering" || transition === "entered";
  let styles = {
    display: "flex",
    flexDirection: "column",
    paddingTop: shouldShow ? "1rem" : "0rem",
    paddingBottom: shouldShow ? "1rem" : "0rem",
    transition: "all ease 1s",
    transitionProperty: "height, padding-top",
    maxWidth: "1400px",
    margin: "0 auto",
    overflow: "hidden"
  };

  if (!isMediumScreenOrLess()) {
    styles.height = shouldShow ? height : "0rem";
  }

  return styles;
};

export default function ({isOpen, height, children}) {
  return <Transition in={isOpen} timeout={1000}>{
    transition =>
      <div style={containerStyle(transition, height)}>
        {transition !== "exited" && children()}
      </div>
  }</Transition>;
}
