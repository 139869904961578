import React from "react";
import Loading from "../sharedComponents/Loading";
import {BandsRepository} from "../businessLogic/repositories/BandsRepository";
import {RevisionsRepository} from "../businessLogic/repositories/RevisionsRepository";
import {PinesRepository} from "../businessLogic/repositories/PinesRepository";
import {ProposalsReviewPage} from "./ProposalsReviewPage";
import {Revision} from "../businessLogic/models/Revision";
import {toast} from "react-toastify";
import routes from "../Routes";
import {withRouter} from "react-router-dom";
import _ from "lodash";

class ProposalsReviewPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isLoading: true};
  }

  componentDidMount() {
    this.initializeState();
  }

  onCloseRevision = (impactDate) => {
    return Revision.closeRevision(impactDate).then(() => {
      this.redirectToProposals();
      toast.success("Se cerro la revision correctamente", {position: toast.POSITION.BOTTOM_RIGHT,});
    });
  };


  replacePine = (newPine, successMessage) => {
    const revision = _.clone(this.state.currentRevision);
    revision.replacePine(newPine);
    this.setState({currentRevision: revision});
    successMessage && toast.success(successMessage, {position: toast.POSITION.BOTTOM_RIGHT,});
  };

  updateRevision = (revision) => {
    if(_.isEmpty(revision.pines)){
      const pines = this.state.currentRevision.getPines();
      revision.setPines(pines);
    }
    this.setState({currentRevision: revision});
  };

  handleWeakSponsorToggle = (pine, currentPine) => {
    const updateSponsorPromise = !pine.isPineWeakSponsor(currentPine) ? pine.addWeakSponsor(currentPine) : pine.removeWeakSponsor(currentPine);
    updateSponsorPromise.then(returnedPine => this.replacePine(returnedPine));
  };


  initializeState = async () => {
    try {
      // Esto se ejecuta primero porque se cachea ya que es necesario para instanciar el resto de los objetos. Si no se hiciera asi, dichas instanciaciones deberían ser asincrónicas
      await BandsRepository.initialize();

      const currentRevision = await RevisionsRepository.getCurrentRevision(true);
      if(!currentRevision.getCurrentProposedPineId()){
        this.redirectToProposals();
        toast.error("No hay ningún pino propuesto aun", {position: toast.POSITION.BOTTOM_RIGHT});
      }
      const currentPine = PinesRepository.getCurrentPine();
      const revisions = await RevisionsRepository.getRevisions();

      this.setState({
        currentRevision: currentRevision,
        currentPine: await currentPine,
        isLoading: false,
        isThereAnyOpenExtraordinaryRevision: RevisionsRepository.isThereAnyOpenExtraordinaryRevision(revisions),
      });
    } catch (error) {
      this.setState({isLoading: false});
    }
  };

  redirectToProposals() {
    this.props.history.push(routes.proposals);
  }

  render() {
    if (this.state.isLoading)
      return <Loading/>;

    if(!this.state.currentRevision.hasPine(this.state.currentRevision.currentProposedPineId)) {
      this.props.history.push(routes.proposals);
      toast.error("No hay ningún pino propuesto aun", {position: toast.POSITION.BOTTOM_RIGHT});
    }

    const pines = this.state.currentRevision.getPines().slice().reverse();
    return <ProposalsReviewPage
      currentPine={this.state.currentPine}
      revisionData={this.state.currentRevision}
      pines={pines}
      onCloseRevision={this.onCloseRevision}
      onWeakSponsorToggle={this.handleWeakSponsorToggle}
      updateRevision={this.updateRevision}
      replacePine={this.replacePine}
      isThereAnyOpenExtraordinaryRevision={this.state.isThereAnyOpenExtraordinaryRevision}
    />;
  }
}

export default withRouter(ProposalsReviewPageContainer);
