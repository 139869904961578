import React, {useState} from "react";
import {CompactCoupon} from "./CompactCoupon";

import {RawLargeCoupon, LargeCouponText} from "./LargeCoupon";
import {MediumCoupon} from "./MediumCoupon";
import {BandsRepository} from "../businessLogic/repositories/BandsRepository";
import {Select} from "../sharedComponents/Select";

const recategorizationColumnStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  marginRight: "1rem"
};

const calculateRisePercentageContainerStyle = (percentage) => ({
  display: "flex",
  visibility: percentage !== null ? "visible" : "hidden",
  flexDirection: "row",
  alignItems: "center",
});

function LargeCoupon({isSelected,onClick,bandState,percentage, color, secondaryColor}) {
  const availableBands = BandsRepository.getSortedBandsDesc();
  const availableBandsNames = availableBands.map(band => band.getName());
  const [selectedBandIndex, setSelectedBandIndex] = useState(() => availableBands.indexOf(bandState.selectedBand));

  return <RawLargeCoupon
    isSelected={isSelected}
    onClick={(event) => {
      if (isSelected) return;
      bandState.handleBandChange(availableBands[selectedBandIndex]);
      onClick(event);
    }}
    color={color}
    secondaryColor={secondaryColor}
    title={"Recategorización"}
    description={<>
            Es la estrategia adecuada para aquellos pinos que están mal ubicados en la escala de rates
      <div style={calculateRisePercentageContainerStyle(percentage)}>
        <b style={{marginRight: "1rem"}}>Porcentaje estimado: </b>
        <b
          style={{
            fontSize: "1.1rem",
            color: "white"
          }}> {percentage} %</b>
      </div>
    </>}
  >
    <div style={{...recategorizationColumnStyle, width: "11rem"}}>
      <b style={{marginBottom: "0.3rem"}}> Recategorizar a: </b>

      <Select
        disabled={!isSelected}
        style={{ width: "8rem"}}
        value={availableBandsNames[selectedBandIndex]}
        onChange={(event) => {
          const newSelectedBandIndex = availableBandsNames.indexOf(event.target.value);
          bandState.handleBandChange(availableBands[newSelectedBandIndex]);
          setSelectedBandIndex(newSelectedBandIndex);
        }}
        options={availableBands}
      />
    </div>
  </RawLargeCoupon>;

}

export function Recategorization({percentage,size,isSelected,onClick,bandState}) {
  if(size === "compact"){
    return <CompactCoupon secondaryColor={"lightpink"} color="cornflowerblue" defaultText={"RE"} textOnHover={percentage+"%"}/>;
  }
  if(size === "large"){
    return <LargeCoupon secondaryColor={"lightpink"} color="cornflowerblue" isSelected={isSelected} onClick={onClick} bandState={bandState} percentage={percentage}/>;
  }
  return <MediumCoupon secondaryColor={"lightpink"} color="cornflowerblue"
    defaultText={`Recategorización a ${bandState.selectedBand.name}`} />;
}
